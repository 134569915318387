import React from "react";

import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import SEO from "../components/seo";

const ContactSuccess = ({ location }) => {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl mx-auto text-center">
              {/* 404 content */}
              <h1 className="h1 mb-4">Thanks Signing up!</h1>
              <br />
              <h2 className="h2 mb-4">
                Please add the Event to your Calendar Below
              </h2>
              <div class="grid place-items-center">
                <a
                  href="https://calendar.google.com/calendar/event?action=TEMPLATE&amp;tmeid=NHA5MXE1OGhzaTg3bjE2MDNwYXAwamt0ZHAgdGVkQGVuY29yZC5jb20&amp;tmsrc=ted%40encord.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    className="h-10 w-auto"
                    border="0"
                    alt="calenderImage"
                    src="https://www.google.com/calendar/images/ext/gc_button1_en.gif"
                    loading="lazy"
                    width={0}
                    height={0}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactSuccess;

export const Head = () => (
  <SEO
    title="Webinar Success | Encord"
    description="Thanks for your message. We'll be in touch with you soon."
  />
);
